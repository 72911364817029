/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://4rcgkl2b2beypkto23bywotc6e.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-k2cwlbpeyjb2nchkjkill7vovq",
    "aws_cognito_identity_pool_id": "us-west-2:9bde1568-5f30-412d-880a-a996a19db257",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_mUNNLg4fg",
    "aws_user_pools_web_client_id": "26muf528o1vmijbrl1slulnht2",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME",
        "PREFERRED_USERNAME",
        "BIRTHDATE",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 20,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "aphrodite-storage-50e9d73e235511-staging",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
