import { withAuthenticator } from '@aws-amplify/ui-react';

// import React from "react";

// import Amplify UI components
import {
  AphroActressCardCollection
} from '../ui-components';

const Actress = () => (
  <div>
    <h1 className="title is-1">This is the Actress Page</h1>
    <p>
      Actress detailed information
    </p>
    <AphroActressCardCollection/>
  </div>

);

export default withAuthenticator(Actress);
