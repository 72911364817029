import { withAuthenticator } from '@aws-amplify/ui-react';

import { useParams } from "react-router-dom";

// import Amplify UI components
import {
  AphroProductCardCollection,
  AphroSeriesDetail
} from '../ui-components';

const Series = () => {
  const { seriesName } = useParams();
  // const productCards = ( <AphroProductCardCollection searchFilter={(item, keyword) =>
  //   item.Product.series.contains(keyword)
  // } searchPlaceholder={seriesName} /> );
  return (
    <div>
      <h1 className="title is-1">This is the Series Page - {seriesName}</h1>
      <p>
        Series of products
      </p>
      <AphroSeriesDetail products={ <AphroProductCardCollection searchPlaceholder={seriesName} /> } />
    </div>
  );
}

export default withAuthenticator(Series);
