import { withAuthenticator } from '@aws-amplify/ui-react';

// import React from "react";

// import Amplify UI components
import {
  AphroSeriesCardCollection
} from '../ui-components';

const Series = () => (
  <div>
    <h1 className="title is-1">This is the Series Page</h1>
    <p>
      Series of products
    </p>
    <AphroSeriesCardCollection/>
  </div>
);

export default withAuthenticator(Series);
