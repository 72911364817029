// import logo from './logo.svg';
import './App.css';

// configure the React app's Amplify instance
import { Amplify } from "aws-amplify";

// import custom pages
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Layout, Home, Actress, Product, Series, AllSeries, About
} from './pages';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

function App({ signOut, user }) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="actress" element={<Actress />} />
          <Route path="product" element={<Product />} />
          <Route path="series">
            <Route index element={<AllSeries />} />
            <Route path=":seriesName" element={<Series />} />
          </Route>
          <Route path="about" element={<About />} />
        </Route>
      </Routes>
    </BrowserRouter>

    // <>
    //   <h1>Hello {user.username}</h1>
    //   <button onClick={signOut}>Sign out</button>
    //   <AphroProductCardCollection />
    // </>

    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default withAuthenticator(App);
