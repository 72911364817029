import { withAuthenticator } from '@aws-amplify/ui-react';

// import React from "react";

// import Amplify UI components
import {
  AphroProductCardCollection
} from '../ui-components';

const Product = () => (
  <div>
    <h1 className="title is-1">This is the Product Page</h1>
    <p>
      Product detailed information
    </p>
    <AphroProductCardCollection/>
  </div>
);

export default withAuthenticator(Product);
